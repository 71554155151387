.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent), linear-gradient(black, black);
}

.link-underline:hover {
  background-size: 100% 1px;
  background-position: 0 100%;
}
.dropdown-container {
  line-height: 2rem; /* Adjust the value to align it properly */
}