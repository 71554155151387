body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  body {
    overflow-x: hidden !important;
}
.container {
    max-width: 100% !important;
    overflow-x: hidden !important;
}
.flex-container {
  display: flex;
  flex-wrap: nowrap;
  /* overflow-x: auto; */
  scroll-behavior: smooth;
}

.flex-container::-webkit-scrollbar {
  display: none;
}
  
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import 'slick-carousel/slick/slick.scss';
@import 'slick-carousel/slick/slick-theme.scss'; */


  